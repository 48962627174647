import { createTheme } from '@mui/material';
import { alpha } from '@mui/system';

export const color = {
  teal: {
    50: '#EFF6F6',
    200: '#E8F0F1',
    300: '#AFD0D2',
    400: '#B7DEDE',
    500: '#5FA3A9',
    700: '#3C7E84',
    900: '#0A444D',
  },
  amber: {
    50: '#FFF8E5',
    300: '#FFDE88',
    400: '#F6BD29',
    500: '#F3B923',
    700: '#EF9E00',
    900: '#2D0000',
  },
  error: {
    50: '#FFD2D2',
    300: '#FFADAC',
    500: '#EB4745',
    700: '#A92222',
  },
  grey: {
    50: '#FFF',
    100: '#F3F4F8',
    150: '#D5D8E5',
    200: '#C1C3CC',
    300: '#AEADAD',
    350: '#A5A8B6',
    400: '#787878',
    500: '#525255',
    600: '#374957',
    700: '#101223',
    800: '#111111',
    900: '#000000',
  },
  orange: {
    500: '#FFD25C',
    600: '#CCA235',
    800: '#A86300',
    900: '#7B4300',
  },
};

function pixelToRem(value: number): string {
  const localeString = (value / 16).toLocaleString('en', {
    maximumFractionDigits: 3,
  });
  return `${localeString}rem`;
}

function getBase(baseValue: number): typeof pixelToRem {
  return (value) => pixelToRem(baseValue * value);
}

const themeBase = createTheme({
  palette: {
    primary: {
      ...color.teal,
      dark: color.teal[700],
      light: color.teal[300],
      main: color.teal[500],
      contrastText: color.grey[50],
    },
    secondary: {
      ...color.amber,
      dark: color.amber[700],
      light: color.amber[300],
      main: color.amber[500],
      contrastText: color.grey[50],
    },
    error: {
      ...color.error,
      dark: color.error[700],
      light: color.error[300],
      main: color.error[500],
      contrastText: color.grey[50],
    },
    grey: { ...color.grey },
    warning: {
      ...color.orange,
      contrastText: color.orange[900],
    },
    common: {
      black: color.grey[700],
      white: color.grey[50],
    },
    background: {
      default: color.grey[50],
      paper: color.grey[50],
    },
    text: {
      disabled: color.grey[400],
      primary: color.grey[500],
      secondary: color.grey[400],
    },
    info: {
      dark: alpha(color.grey[200], 0.3),
      light: color.teal[300],
      main: color.grey[100],
      contrastText: color.teal[500],
    },
    light: {
      ...color.teal,
      light: color.grey[300],
      dark: color.grey[100],
      main: color.teal[200],
      contrastText: color.teal[500],
    },
    divider: `${color.grey[700]}20`,
    mode: 'light',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  spacing: getBase(4),
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        autoCapitalize: 'capitalize',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          textTransform: 'capitalize',
          minWidth: ownerState.size === 'small' ? 'auto' : theme.spacing(11),
          height: theme.spacing(10),
          padding:
            ownerState.size === 'small'
              ? theme.spacing(ownerState.variant === 'outlined' ? 0.75 : 1)
              : theme.spacing(
                  ownerState.variant === 'outlined' ? 2.75 : 3,
                  ownerState.variant === 'outlined' ? 3.75 : 4,
                ),
          borderRadius: theme.spacing(5.5),
          '& .MuiButton-startIcon': {
            marginLeft: 0,
          },
          lineHeight: 1.25,
          letterSpacing: 1,
          fontWeight: 500,
          [themeBase.breakpoints.up('sm')]: {
            lineHeight: 1.25,
          },
          [themeBase.breakpoints.up('sm')]: {
            height: theme.spacing(11),
          },
        }),
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        sx: {
          padding: (theme) => theme.spacing(3.5, 5),
          textTransform: 'none',
          fontWeight: 'normal',
          height: {
            xs: 40,
            md: 44,
          },
          borderRadius: {
            xs: pixelToRem(100),
            md: pixelToRem(100),
          },
          fontSize: {
            xs: pixelToRem(16),
            md: pixelToRem(16),
          },
          lineHeight: '1em',
        },
      },
      styleOverrides: {
        root: {
          outline: 'none',
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        exclusive: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: '0 !important',
          marginBottom: '0 !important',
        },
      },
    },
  },
});

export const theme = createTheme({
  ...themeBase,
  typography: {
    fontFamily: '"Pangram", Arial, san-serif',
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontSize: pixelToRem(24),
      lineHeight: 1.25,
      fontWeight: 700,
      [themeBase.breakpoints.up('sm')]: {
        fontSize: pixelToRem(34),
        lineHeight: 1.5,
      },
    },
    h2: {
      fontSize: pixelToRem(36),
      lineHeight: 1.4,
      fontWeight: 700,
      [themeBase.breakpoints.up('sm')]: {
        fontSize: pixelToRem(36),
        lineHeight: 1.1,
      },
    },
    h3: {
      fontSize: pixelToRem(20),
      lineHeight: 2,
      fontWeight: 700,
      [themeBase.breakpoints.up('sm')]: {
        fontSize: pixelToRem(24),
        lineHeight: 1.75,
      },
    },
    h4: {
      fontSize: pixelToRem(20),
      lineHeight: 1,
      fontWeight: 700,
    },
    h5: {
      fontSize: pixelToRem(16),
      lineHeight: 1.25,
      fontWeight: 700,
    },
    h6: {
      fontSize: pixelToRem(14),
      lineHeight: 1,
      fontWeight: 700,
    },
    body1: {
      fontSize: pixelToRem(16),
      lineHeight: 1.5,
      fontWeight: 400,
      [themeBase.breakpoints.up('sm')]: {
        fontSize: pixelToRem(20),
        lineHeight: 2,
        fontWeight: 400,
      },
    },
    body2: {
      fontSize: pixelToRem(14),
      lineHeight: 1.5,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: pixelToRem(16),
      lineHeight: 1.5,
      fontWeight: 500,
      [themeBase.breakpoints.up('sm')]: {
        fontSize: pixelToRem(20),
        lineHeight: 2,
        fontWeight: 500,
      },
    },
    subtitle2: {
      fontSize: pixelToRem(14),
      lineHeight: 1.5,
      fontWeight: 400,
    },

    button: {
      [themeBase.breakpoints.up('sm')]: {
        lineHeight: '1rem',
        letterSpacing: 1,
        fontWeight: 500,
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    light: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    light?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    light: true;
  }
}
