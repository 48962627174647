import { makeAutoObservable } from 'mobx';

class QuestionnaireModalStore {
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  showModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}

export default new QuestionnaireModalStore();
