import { makeAutoObservable, runInAction } from 'mobx';
import { captureException } from '@sentry/nextjs';

import { Notification } from '@/types/Notifications';
import notificationsService from '@/services/notificationsService';

class NotificationsStore {
  totalUnread = 0;

  currentPage = 0;

  hasNextPage = false;

  notifications: Notification[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchNotifications({ page = 1 }: { page?: number }) {
    try {
      this.setLoading(true);

      const data = await notificationsService.getNotifications({
        page,
      });

      let newNotifications = data?.results || [];

      if (this.notifications?.length && page > 1) {
        newNotifications = [...this.notifications, ...newNotifications];
      }

      runInAction(() => {
        this.currentPage = page;
        this.hasNextPage = data?.count - newNotifications.length > 0;
        this.notifications = newNotifications;
      });
    } catch (error) {
      captureException(error);
    } finally {
      this.setLoading(false);
    }
  }

  async fetchUnreadCount() {
    try {
      const data = await notificationsService.getUnreadNotificationsCount();
      runInAction(() => {
        this.totalUnread = data?.count;
      });
    } catch (error) {
      captureException(error);
    }
  }

  async readAllNotifications() {
    try {
      this.setLoading(true);
      await notificationsService.readAllNotifications();
      this.fetchUnreadCount();
      this.fetchNotifications({ page: 1 });
    } catch (error) {
      captureException(error);
    } finally {
      this.setLoading(false);
    }
  }

  async readNotification(notificId: number) {
    try {
      this.setLoading(true);
      await notificationsService.readNotification(notificId);
      this.fetchUnreadCount();
    } catch (error) {
      captureException(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.isLoading = loading;
    });
  }

  resetList() {
    this.currentPage = 0;
    this.hasNextPage = false;
    this.notifications = [];
    this.isLoading = false;
  }

  reset() {
    this.totalUnread = 0;
    this.resetList();
  }
}

export default new NotificationsStore();
