/* eslint-disable import/no-cycle */
import QuestionnaireModalStore from '@/stores/QuestionnareModalStore';
import AuthModalStore, { AuthModalType } from '@/stores/AuthModalStore';

export function loggedActionsGuard(
  isLoggedIn: boolean,
  mustCreateVillage: boolean,
  callback: (args: unknown) => unknown,
  modalType?: AuthModalType,
): (args: Array<any>) => any {
  if (!isLoggedIn) {
    return () => AuthModalStore.showModal(modalType || 'login');
  }
  if (mustCreateVillage) {
    return () => QuestionnaireModalStore.showModal();
  }
  return (...args) => callback.apply(this, args);
}
