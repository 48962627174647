/* eslint-disable import/no-cycle */
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import Router from 'next/router';

import * as Sentry from '@sentry/nextjs';

import { DEFAULT_ERROR_MESSAGE } from '@/common/constants/error-messages';
import { api } from '@/services/api';
import { IUser, ICmsUser } from '@/types/Content/User';
import userService from '@/services/userService';
import {
  COMPANY_PATH,
  FEED_PATH,
  PROFILE_BUILDER,
  SIGNUP_PB_REDIRECT_BLACKLIST,
  VILLAGE_PATH,
} from '@/common/constants/routes';
import { IAuthResponse, IOAuthRequest } from '@/types/Auth';
import VillagePageStore from '@/stores/VillagePageStore';
import AuthModalStore from '@/stores/AuthModalStore';
import { AxiosResponse } from 'axios';
import { PBStepNames } from '@/domains/ProfileBuilder/constants/PBStepName';
import TutorialStore from '@/stores/TutorialStore';
import { FridgeDoorVillageType } from '@/types/Village/FridgeDoorVillage';
import { GUEST_USER } from '@/common/constants/user';
import { CompanyStore, OrgStore } from '@/stores/index';
import toast from 'react-hot-toast';
import xw from 'xwind';
import { getLandingCoachSlugQuery } from '@/utils/common';
import WishlistStore from './WishlistStore';
import HomePageStore from './HomePageStore';
import FridgeDoorStore from './FridgeDoorStore';
import NotificationsStore from './NotificationsStore';

export type AuthProvider = 'none' | 'email' | 'google';

function persist(user): Promise<unknown> {
  return new Promise((resolve) => {
    const {
      first_name: firstName,
      friend_name: friendName,
      roles,
      questionnaire,
      wishlist_visited: wishlistVisited,
      homepage_visited: homepageVisited,
      villages,
    } = user ?? {};
    localStorage.setItem(
      'user',
      JSON.stringify({
        first_name: firstName,
        friend_name: friendName,
        roles,
        questionnaire,
        wishlist_visited: wishlistVisited,
        homepage_visited: homepageVisited,
        villages,
      }),
    );
    resolve(undefined);
  });
}

class AuthStore {
  inactiveUser = null;

  user: IUser | null = null;

  cmsUser: ICmsUser = null;

  authProvider: AuthProvider = null;

  providerEmail: string = null;

  companyName: string = null;

  showMore = false;

  isLoadingUser: boolean;

  processingUserImage = false;

  private _loadingAuth = false;

  _errors: any;

  constructor() {
    makeAutoObservable(this);
  }

  get providerInfoExists(): boolean {
    return Boolean(this.providerEmail && this.authProvider);
  }

  get authProviderType() {
    return {
      isGoogle: this.authProvider === 'google',
      isEmail: this.authProvider === 'email',
    };
  }

  get isSponsoredUser(): boolean {
    return this.cmsUser?.subscription === 'employerSponsored' || !!OrgStore.org;
  }

  get loadingAuth(): boolean {
    return this._loadingAuth;
  }

  set loadingAuth(value: boolean) {
    this._loadingAuth = value;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  get isGuest() {
    return !this.user;
  }

  get isOrganizationMember() {
    return this.cmsUser?.organizationsRels?.some(
      (item) => item.role === 'member',
    );
  }

  priorityRole = {
    label: GUEST_USER,
  };

  cancerType = 'breast';

  get errors() {
    const errorData = this._errors?.data;

    if (errorData?.error?.message) {
      return [errorData.error.message];
    }

    if (Array.isArray(errorData)) {
      return errorData;
    }

    if (typeof errorData === 'object') {
      return Object.values(errorData);
    }

    if (this._errors) {
      return [DEFAULT_ERROR_MESSAGE];
    }

    return null;
  }

  private setUser(data?: IAuthResponse) {
    if (data) {
      const { access_token: accessToken, user } = data ?? {};
      if (accessToken) {
        localStorage.setItem('access_token', accessToken);
        Cookies.set('ov_authorized', 'true');
      }
      this.user = user;
      persist(user);
    } else {
      localStorage.removeItem('access_token');
      Cookies.remove('ov_authorized');
      this.user = null;
    }

    if (this.user) {
      this.storeInactiveUser();
    }
  }

  async patchUser(userData: Partial<IUser>) {
    const newUser = await userService.patchUser(userData);
    if (newUser) {
      this.user = newUser;
      this.getCmsUser();
    }
  }

  getCmsUser() {
    return userService.getCurrentUser().then((cmsUser) => {
      localStorage.setItem('cmsUser', JSON.stringify(cmsUser));
      this.cmsUser = cmsUser;
    });
  }

  private async successResponseHandler({
    data,
    status,
  }: AxiosResponse<IAuthResponse>) {
    if (!data) return null;
    this.setUser(data);
    this.getCmsUser();

    if (status === 201) {
      if (
        !data?.user?.villages?.[0]?.slug &&
        !SIGNUP_PB_REDIRECT_BLACKLIST.some((item) =>
          Router.asPath.startsWith(item),
        )
      ) {
        Router.push(`${PROFILE_BUILDER}/${PBStepNames.USER_ROLE}`);
      }
    }
    TutorialStore.setIsTutorialActive(true);

    return data;
  }

  private errorResponseHandler(error) {
    if (error?.response?.status === 406) {
      this._errors = {
        data: [
          'To continue with Facebook you need to allow the email address access.',
        ],
      };
    } else {
      this._errors = error?.response;
    }

    Sentry.captureException(error);
  }

  async fetchUser() {
    try {
      this.isLoadingUser = true;
      const response = await api.get<IUser>('/user/');
      this.setUser({ user: response.data });
      this.getCmsUser();
    } catch (error) {
      if (error?.response?.status === 401) {
        this.logout();
      }
    } finally {
      this.isLoadingUser = false;
    }
  }

  async updateUser() {
    try {
      const response = await api.get('/user/');
      this.setUser({ user: response.data });
    } catch (error) {
      //
    }
  }

  async editUser(data) {
    try {
      const response = await api.patch('/user/', data);
      this.setUser({ user: response.data });
    } catch (error) {
      this.errorResponseHandler(error);
    }
  }

  async updateUserImage(file: File) {
    runInAction(() => {
      this.setProcessingImage(true);
    });
    await userService.updateUserImage({ file });
    await this.getCmsUser();

    runInAction(() => {
      this.setProcessingImage(false);
    });
  }

  signup(values) {
    this._errors = null;

    return api
      .post<IAuthResponse>('/auth/signup', {
        ...values,
        claimed_company: this.companyName,
      })
      .then((response) =>
        response?.data?.access_token
          ? this.successResponseHandler(response)
          : response?.data,
      )
      .catch((error) => this.errorResponseHandler(error));
  }

  // eslint-disable-next-line class-methods-use-this
  verifyEmail(token: string) {
    return api.post<IAuthResponse>('/auth/verify_email', { token });
  }

  oauth(data: IOAuthRequest) {
    this._errors = null;

    return api
      .post<IAuthResponse>('/auth/oauth', {
        ...data,
        claimed_company: this.companyName,
      })
      .then((response) => this.successResponseHandler(response))
      .catch((error) => this.errorResponseHandler(error));
  }

  getAuthMethod(email: string) {
    if (!email) return Promise.resolve();

    this._errors = null;
    this.loadingAuth = true;
    return api
      .get('/auth/auth_method', { params: { email } })
      .then(({ data }) => {
        this.authProvider = data?.provider || 'none';
        this.showMore = data?.show_more ?? false;
        this.providerEmail = email;

        this.loadingAuth = false;
      })
      .catch((e) => {
        this.loadingAuth = false;
        throw e;
      });
  }

  login(values) {
    this._errors = null;
    this.isLoadingUser = true;
    return api
      .post('/auth/signin', values)
      .then(({ data }) => {
        this.setUser(data);
        this.getCmsUser();
        this.isLoadingUser = false;
        return data;
      })
      .catch((err) => {
        this.isLoadingUser = false;
        this._errors = err?.response;
        throw err;
      });
  }

  logout() {
    this.setUser();
    this.cmsUser = null;

    WishlistStore.reset();
    HomePageStore.reset();
    FridgeDoorStore.reset();
    NotificationsStore.reset();
    localStorage.clear();
  }

  storeInactiveUser(values?: any) {
    if (values) {
      this.inactiveUser = values;
      localStorage.setItem('inactiveUser', JSON.stringify(values));
    } else {
      this.inactiveUser = null;
      localStorage.setItem('inactiveUser', null);
    }
  }

  reset() {
    this._errors = null;
  }

  resetProvider() {
    this.authProvider = null;
    this.providerEmail = null;
    this.companyName = null;
    this.showMore = false;
  }

  setProcessingImage(loading: boolean) {
    this.processingUserImage = loading;
  }

  hydrate() {
    try {
      if (process.browser) {
        const user = localStorage?.getItem('user');
        if (user) {
          this.user = JSON.parse(user);
          const cmsUser = localStorage?.getItem('cmsUser');
          this.cmsUser = JSON.parse(cmsUser);

          return;
        }

        const inactiveUser = localStorage?.getItem('inactiveUser');
        if (inactiveUser) {
          this.inactiveUser = JSON.parse(inactiveUser);
        }
      }
    } catch (error) {
      //
    }
  }
}

const store = new AuthStore();
store.hydrate();

reaction(
  () => store.isLoggedIn,
  async (isLoggedIn) => {
    const closeModal = () => {
      store.loadingAuth = false;
      AuthModalStore.closeModal();
    };

    if (!isLoggedIn) {
      window.dataLayer?.push({ event: 'logout' });
      return;
    }

    if (Router.query?.org) {
      const organizationList = await userService.acceptOrganizationInvite();
      if (organizationList) {
        const [organization] = organizationList;
        CompanyStore.company = organization;
        toast(
          `You have been added to the organization ${organization.title}!`,
          {
            duration: 3000,
            style: xw`px-16 text-active`,
          },
        );
        closeModal();
        return;
      }
    }

    const org = store.user.organizationsRel?.find(
      (item) => item.role === 'member',
    );

    if (org) {
      Router.push(`${COMPANY_PATH}/${org.organization.slug}`);
      closeModal();
      return;
    }

    FridgeDoorStore.fetchAllVillages();
    if (Router.query.in) {
      const { data: villages } = await api.post<FridgeDoorVillageType[]>(
        '/village/invites/accept/',
      );
      if (villages.length && villages[0]?.slug) {
        FridgeDoorStore.fetchVillageById(villages[0].slug);
        VillagePageStore.fetchVillage(villages[0].slug);
        Router.replace(`${VILLAGE_PATH}/${villages[0].slug}/home`);
        closeModal();
        return;
      }
    }

    closeModal();

    // if (store.user.is_new) {
    //   return;
    // }

    await FridgeDoorStore.fetchDefaultVillage();

    if (FridgeDoorStore.currentVillagePath) {
      Router.push({
        pathname: `${FridgeDoorStore.currentVillagePath}/home`,
        query: getLandingCoachSlugQuery(Router),
      });
    } else if (
      !SIGNUP_PB_REDIRECT_BLACKLIST.some((item) =>
        Router.asPath.startsWith(item),
      )
    ) {
      Router.push(`${FEED_PATH}`);
    }
  },
);

reaction(
  () => store.user,
  (user) => {
    if (!user) return;

    window.dataLayer?.push({
      event: 'setUserProfile',
      user: {
        id: user.id,
        first_name: user.first_name,
      },
    });

    window.dataLayer?.push({
      event: 'userChangeEvent',
      user_name: `${user.first_name} ${user.last_name}`,
      user_email: user.email,
    });
  },
);
export default store;
