import * as Sentry from '@sentry/nextjs';

import { FilterValues } from '@/domains/Groups/constants/FilterValues';
import { Group } from '@/types/Content/Group';
// eslint-disable-next-line import/no-cycle
import discoverService from '@/services/discoverService';
import { HTTPListResponse } from '@/types/HTTPListResponse';
import { cmsProxyApi } from './cmsProxyApi';

type JoinOrLeaveGroupParams = {
  groupId: string;
  userId: string;
};

export interface ISubscriptionGroupQuery {
  page: number;
  tags: string[];
  contentTypeList?: string;
  contentTrending?: boolean;
  includeTotalCount: boolean;
}

const subscriptionGroupService = {
  async getAll(
    params: ISubscriptionGroupQuery,
  ): Promise<HTTPListResponse<Group>> {
    try {
      const { data } = await cmsProxyApi.get('/subscription-groups', {
        params,
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getAllWithLifeEvents(): Promise<HTTPListResponse<Group>> {
    try {
      const { data } = await cmsProxyApi.get('/subscription-groups/all');
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getSubscribable(tags: string[]): Promise<HTTPListResponse<Group>> {
    try {
      const { data } = await cmsProxyApi.get<HTTPListResponse<Group>>(
        '/subscription-groups/subscribable',
        {
          params: {
            tags,
          },
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getGroup(
    slug: string,
    contentRelatedTags: Array<string> = [],
    filter: Array<FilterValues>,
    trending = true,
  ): Promise<Group> {
    const typeFilter = discoverService.getFilter(filter);

    try {
      const { data } = await cmsProxyApi.get(`/subscription-groups/${slug}`, {
        params: {
          contentRelatedTags,
          typeFilter,
          trending,
        },
      });

      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async joinGroup({ userId, groupId }: JoinOrLeaveGroupParams): Promise<any> {
    try {
      return await cmsProxyApi.post(
        `/subscription-groups/${groupId}/subscribe/${userId}`,
      );
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async leaveGroup({ userId, groupId }: JoinOrLeaveGroupParams): Promise<any> {
    try {
      return await cmsProxyApi.delete(
        `/subscription-groups/${groupId}/subscribe/${userId}`,
      );
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default subscriptionGroupService;
