/* eslint-disable import/no-cycle */
import userService from '@/services/userService';
import { FridgeDoorVillageType } from '@/types/Village/FridgeDoorVillage';
import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import ProfileBuilderStore from '@/stores/ProfileBuilderStore';
import { getPersonalizedTag } from '@/domains/home/utils';
import { VILLAGE_PATH } from '@/common/constants/routes';
import { getPersonalizedTagCancer } from '@/utils/getPersonalizedCancerTagCancer';
import { api } from '../services';

function persist(defaultVillage): Promise<unknown> {
  return new Promise((resolve) => {
    if (defaultVillage?.slug) {
      localStorage.setItem('defaultVillageId', defaultVillage.slug);
    }
    resolve(undefined);
  });
}

class FridgeDoorStore {
  allVillagesLoading = false;

  defaultVillage: FridgeDoorVillageType | null = null;

  defaultVillageCachedDate = 0;

  villages: Array<FridgeDoorVillageType> = null;

  villageMap: Map<string, FridgeDoorVillageType> = new Map();

  private _currentVillageId = null;

  createdUncompletedVillageId = null;

  villageIdRedirects = {};

  userFeed = null;

  currentFeedPage = 0;

  hasNextFeedPage = false;

  loadingFeed = false;

  constructor() {
    makeAutoObservable(this);
  }

  get currentVillageId() {
    return this._currentVillageId;
  }

  set currentVillageId(value: string) {
    this._currentVillageId = value;
  }

  get currentVillagePath(): string | null {
    return this.currentVillageId
      ? `${VILLAGE_PATH}/${this.currentVillageId}`
      : null;
  }

  get currentVillage() {
    return this.villageMap.get(this._currentVillageId);
  }

  get isAdmin() {
    return this.currentVillage?.subscription_info?.is_admin;
  }

  get villageUserTags() {
    const isLocalStorage = typeof window !== 'undefined' && window.localStorage;
    const lsTags = isLocalStorage
      ? JSON.parse(localStorage?.getItem('ov_villageUserTags'))
      : null;
    let newTags = [
      getPersonalizedTag(
        this.currentVillage?.subscription_info?.role?.label || 'all',
      ),
      getPersonalizedTagCancer(this.currentVillage?.cancer_type || 'all'),
    ];
    if (!this.currentVillage) {
      newTags = lsTags || newTags;
    }
    if (isLocalStorage) {
      localStorage.setItem('ov_villageUserTags', JSON.stringify(newTags));
    }
    return newTags;
  }

  get isMustCreateVillage() {
    return this.villageMap.size === 0 && !this.defaultVillage;
  }

  setDefaultVillage(value: FridgeDoorVillageType) {
    if (value) {
      this.defaultVillageCachedDate = Date.now();
      this.villageMap.set(value.slug, value);
      this._currentVillageId = value.slug;
      this.defaultVillage = value;
      persist(value);
    }
  }

  async fetchAllVillages() {
    try {
      runInAction(() => {
        this.allVillagesLoading = true;
      });
      const { data } = await api.get('/village/all/');
      if (Array.isArray(data)) {
        this.villages = data;
        data.forEach((item) => {
          this.villageMap.set(item?.slug, item);
        });
      }
    } catch (error) {
      captureException(error);
    } finally {
      runInAction(() => {
        this.allVillagesLoading = false;
      });
    }
  }

  async fetchDefaultVillage(): Promise<FridgeDoorVillageType | null> {
    if (
      this.defaultVillage?.slug &&
      Date.now() - this.defaultVillageCachedDate <= 5 * 60 * 1000
    ) {
      return this.defaultVillage;
    }
    try {
      const { data } = await api.get<FridgeDoorVillageType>('/village/');
      if (data) {
        this.setDefaultVillage(data);
        persist(data);
      }
    } catch (error) {
      if (error?.response?.status !== 401) captureException(error);
    }
    return this.defaultVillage;
  }

  async fetchVillageById(villageId: string) {
    const { data } = await api.get(`/village/${villageId}/`);
    if (data) {
      this.villageMap.set(data.slug, data);
    }

    return data;
  }

  clearCreatedUncompletedVillage() {
    if (this.createdUncompletedVillageId) {
      this.villageMap.delete(this.createdUncompletedVillageId);
      this.createdUncompletedVillageId = null;
    }
  }

  async fetchUserFeed(page = 0) {
    this.loadingFeed = true;
    try {
      const { results, count } = await userService.getUserFeed(page);
      let newFeed = results;
      if (this.userFeed?.length && page) {
        newFeed = [...this?.userFeed, ...results];
      }
      const feedSize = newFeed?.length;
      const hasNextPage = count - feedSize > 0;
      runInAction(() => {
        this.userFeed = newFeed;
        this.currentFeedPage = page;
        this.hasNextFeedPage = hasNextPage;
      });
    } catch (e) {
      captureException(e);
    } finally {
      this.loadingFeed = false;
    }
  }

  reset() {
    this.userFeed = null;
    this.allVillagesLoading = false;
    this.villages = null;
    this.villageMap = new Map();
    this.villageIdRedirects = {};
    this.createdUncompletedVillageId = null;
    this.defaultVillage = null;
    this._currentVillageId = null;
  }

  hydrate() {
    try {
      if (process.browser) {
        const defaultVillageId = localStorage?.getItem('defaultVillageId');
        const accessToken = localStorage?.getItem('access_token');
        if (defaultVillageId && accessToken) {
          this._currentVillageId = defaultVillageId;
          this.fetchVillageById(defaultVillageId);
        } else if (accessToken) {
          this.fetchDefaultVillage();
        }
      }
    } catch (error) {
      captureException(error);
    }
  }
}

const store = new FridgeDoorStore();
store.hydrate();

reaction(
  () => store.villages,
  (villages) => {
    ProfileBuilderStore.setCanSelectRole(
      !villages?.find(
        (item) =>
          item?.subscription_info?.role?.label?.toLowerCase() === 'patient',
      ),
    );
  },
);

export default store;
