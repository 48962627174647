import { makeAutoObservable, runInAction } from 'mobx';
import { captureException } from '@sentry/nextjs';

import { TutorialStep } from '@/types/Content/Tutorial';
import tutorialService from '@/services/tutorialService';

export const getTutorialStepId = (i: number): string => {
  return `tutorial-step-${i}`;
};

class TutorialStore {
  isTutorialActive = false;

  currentStep = 0;

  steps: TutorialStep[] = [];

  tutorialAnchor: HTMLElement = null;

  isLoading = false;

  isOnboardingFinished = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchTutorial() {
    try {
      this.setLoading(true);

      const data = await tutorialService.getTutorial();
      runInAction(() => {
        this.steps = data;
        this.currentStep = 0;
        this.setAnchorEl();
      });
    } catch (error) {
      captureException(error);
    } finally {
      this.setLoading(false);
    }
  }

  setIsTutorialActive(active: boolean) {
    runInAction(() => {
      const isLocalStorage =
        typeof window !== 'undefined' && window.localStorage;

      this.isTutorialActive = active;
      if (isLocalStorage) {
        localStorage.setItem('showTutorial', JSON.stringify(active));
      }

      if (!active) {
        this.reset();
      }
    });
  }

  setIsOnboardingFinished(finished: boolean) {
    const isLocalStorage = typeof window !== 'undefined' && window.localStorage;

    this.isOnboardingFinished = finished;
    if (isLocalStorage) {
      localStorage.setItem('isOnboardingFinished', JSON.stringify(finished));
    }
  }

  moveToNextStep() {
    if (this.currentStep === this.steps.length - 1) {
      this.setIsTutorialActive(false);
    } else {
      runInAction(() => {
        this.currentStep += 1;
        this.setAnchorEl();
      });
    }
  }

  moveToPrevStep() {
    runInAction(() => {
      this.currentStep -= 1;
      this.setAnchorEl();
    });
  }

  setAnchorEl() {
    this.tutorialAnchor =
      document.getElementById(getTutorialStepId(this.currentStep)) ||
      document.getElementById(getTutorialStepId(0));
  }

  setLoading(loading: boolean) {
    runInAction(() => {
      this.isLoading = loading;
    });
  }

  reset() {
    this.isTutorialActive = false;
    this.currentStep = 0;
    this.steps = [];
    this.tutorialAnchor = null;
    this.isLoading = false;
    this.setIsOnboardingFinished(false);
  }

  hydrate() {
    try {
      if (process.browser) {
        const tutorialActiveVal = localStorage?.getItem('showTutorial');
        this.isTutorialActive = tutorialActiveVal
          ? JSON.parse(tutorialActiveVal) || false
          : false;

        const onboardingFinishedVal = localStorage?.getItem(
          'isOnboardingFinished',
        );
        this.isOnboardingFinished = onboardingFinishedVal
          ? JSON.parse(onboardingFinishedVal) || false
          : false;
      }
    } catch (error) {
      captureException(error);
    }
  }
}

const store = new TutorialStore();
store.hydrate();

export default store;
