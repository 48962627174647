import { captureException } from '@sentry/nextjs';

import type { VillagePage } from '@/types/Village/VillagePage';
import { cmsProxyApi } from './cmsProxyApi';
import { api } from './api';
import { getVillageFromHTTPResponse } from './adapters/villageAdapter';

async function getBySlug({ slug }: { slug: string }): Promise<VillagePage> {
  try {
    cmsProxyApi.interceptors.request.use((req) => req);

    const result = await api.get(`/village/${slug}/`);
    const fromApi = result.data;

    return getVillageFromHTTPResponse({ fromApi });
  } catch (error) {
    captureException(error);

    return null;
  }
}

export default {
  getBySlug,
};
