import guidanceService, { TutorialVideo } from '@/services/tutorialsService';
import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, runInAction } from 'mobx';

export const firstPage = 0;

class TutorialsStore {
  loading = false;

  page = firstPage;

  nextPageExists = false;

  videos: TutorialVideo[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchVideos({
    page = firstPage,
    perPage = 20,
    refresh = false,
  }: {
    page?: number;
    perPage?: number;
    refresh?: boolean;
  } = {}) {
    try {
      if (this.loading) return;
      if (!refresh && this.videos?.length && page <= this.page) return;

      runInAction(() => {
        this.loading = true;
      });

      this.page = this.videos?.length ? page : firstPage;

      const data = await guidanceService.getVideos({
        page: this.page,
        perPage,
      });

      if (this.page > firstPage) {
        this.videos = [...this.videos, ...(data?.results || [])];
      } else {
        this.videos = data?.results || [];
      }
      this.nextPageExists =
        !!data?.next || (data?.count || 0) + page * perPage < data.totalCount;
    } catch (error) {
      captureException(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async loadNextPage() {
    if (!this.nextPageExists) return;

    await this.fetchVideos({ page: this.page + 1 });
  }
}

const store = new TutorialsStore();

export default store;
