import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, runInAction } from 'mobx';
import navigatorsService from '@/services/navigatorsService';
import { NavigatorsHeader } from '@/types/Content/NavHeader';
import { Navigator } from '@/types/Content/Navigators';

export const firstPage = 0;

class NavigatorsStore {
  loadingHeader = false;

  headerInfo: NavigatorsHeader = null;

  loading = false;

  page = firstPage;

  nextPageExists = false;

  navigators: Navigator[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchNavHeaders() {
    try {
      if (this.loadingHeader) return;

      runInAction(() => {
        this.loadingHeader = true;
      });

      const data = await navigatorsService.getNavHeaders();

      this.headerInfo = data || null;
    } catch (error) {
      captureException(error);
    } finally {
      runInAction(() => {
        this.loadingHeader = false;
      });
    }
  }

  async fetchNavigators({
    page = firstPage,
    perPage,
    homePageOnly,
    refresh = false,
  }: {
    page?: number;
    perPage?: number;
    homePageOnly?: boolean;
    refresh?: boolean;
  } = {}) {
    try {
      if (this.loading) return;
      if (!refresh && this.navigators?.length && page <= this.page) return;

      runInAction(() => {
        this.loading = true;
      });

      this.page = this.navigators?.length ? page : firstPage;

      const data = await navigatorsService.getNavigators({
        page: this.page,
        perPage,
        homePageOnly,
      });

      if (this.page > firstPage) {
        this.navigators = [...this.navigators, ...(data?.result || [])];
      } else {
        this.navigators = data?.result || [];
      }
      this.nextPageExists = data?.next || false;
    } catch (error) {
      captureException(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async loadNextPage() {
    if (!this.nextPageExists) return;

    await this.fetchNavigators({ page: this.page + 1 });
  }
}

const store = new NavigatorsStore();

export default store;
