import * as Sentry from '@sentry/nextjs';
import { makeAutoObservable } from 'mobx';

import wishlistService from '@/services/wishlistService';
import { WishlistResponse } from '@/types/Village/WishlistPage';

const initialValue: WishlistResponse = {
  is_public: null,
  products: [],
  owner: null,
};

class SharedWishlistStore {
  isLoading = false;

  private _wishlist: WishlistResponse = initialValue;

  constructor() {
    makeAutoObservable(this);
  }

  get wishlist() {
    return this._wishlist;
  }

  set wishlist(value) {
    this._wishlist = value;
  }

  async fetchWishlist(slug: string) {
    try {
      this.isLoading = true;
      this._wishlist = await wishlistService.getProducts({
        villageSlug: slug,
      });
    } catch (error) {
      Sentry.captureException(error);
    }

    this.isLoading = false;
  }

  reset() {
    this._wishlist = initialValue;
  }
}

export default new SharedWishlistStore();
