import { WishlistResponse } from '@/types/Village/WishlistPage';
import * as Sentry from '@sentry/nextjs';
import { captureException } from '@sentry/nextjs';
import { api } from './api';
import { cmsProxyApi } from './cmsProxyApi';

type ProductPayloadType = {
  title: string;
  url: string;
  image_url: string;
  price: string;
  description: string;
  quantity: number;
  needed_date: any;
  sanity_types: string;
};

const wishlistService = {
  async getProducts({
    villageSlug,
  }: {
    villageSlug: string;
  }): Promise<WishlistResponse> {
    try {
      const responseFromAPI = await cmsProxyApi.get(
        `/villages/${villageSlug}/wishlist`,
      );

      return responseFromAPI.data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async removeProduct({
    productId,
    villageSlug,
  }: {
    productId: number;
    villageSlug?: string;
  }): Promise<boolean> {
    try {
      const apiUrl = villageSlug
        ? `/wishlist/v/${villageSlug}/product/${productId}`
        : `/wishlist/products/${productId}`;

      await api.delete(apiUrl);

      return true;
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  },

  async createProduct(slug: string, payload: ProductPayloadType): Promise<any> {
    try {
      return await api.post(`wishlist/v/${slug}/product/`, payload);
    } catch (error) {
      captureException(error);

      return null;
    }
  },

  async updateProduct(
    slug: string,
    djangoId: number,
    payload: ProductPayloadType,
  ): Promise<any> {
    try {
      return await api.patch(`wishlist/v/${slug}/product/${djangoId}`, payload);
    } catch (error) {
      captureException(error);

      return null;
    }
  },

  async updateQuantity({
    villageSlug,
    quantity,
    operation,
    wishlistProductId,
  }: {
    quantity: number;
    villageSlug: string;
    wishlistProductId: number;
    operation: 'reserve' | 'undo-reserve' | 'purchase';
  }): Promise<unknown | null> {
    if (quantity === 0) return null;

    try {
      const apiUrl = `/wishlist/v/${villageSlug}/status/`;

      const responseFromAPI = await api.patch(apiUrl, {
        quantity,
        operation,
        wishlist_product_id: wishlistProductId,
      });

      return responseFromAPI.data?.count ?? 0;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default wishlistService;
