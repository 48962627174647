import * as Sentry from '@sentry/nextjs';

import { TutorialRes, TutorialStep } from '@/types/Content/Tutorial';
import { cmsProxyApi } from './cmsProxyApi';

const tutorialService = {
  async getTutorial(): Promise<TutorialStep[]> {
    try {
      const { data } = await cmsProxyApi.get<TutorialRes>(
        `/tutorial/onboarding?expands=steps`,
      );
      return data?.steps;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default tutorialService;
