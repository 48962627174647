/* eslint-disable no-param-reassign */
import axios from 'axios';
import getPublicConfig from '@/utils/getPublicConfig';

const publicRuntimeConfig = getPublicConfig();

const baseURL =
  typeof window !== 'undefined' &&
  window.location.host &&
  publicRuntimeConfig.APP_ENV === 'development'
    ? 'http://localhost:3001'
    : publicRuntimeConfig.CMS_PROXY_URL;

const cmsProxyApi = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

cmsProxyApi.interceptors.request.use(
  async (config) => {
    let accessToken;
    if (typeof window !== 'undefined' && window.localStorage) {
      accessToken = localStorage?.getItem('access_token');
    }

    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export { cmsProxyApi };
