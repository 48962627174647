import type { VillagePage } from '@/types/Village/VillagePage';

interface AdapterParams {
  fromApi: any;
}

export function getVillageFromHTTPResponse(data: AdapterParams): VillagePage {
  const { fromApi = {} } = data;

  return {
    // bio: fromCms?.bio?.all,
    // bioJson: fromCms?.bioJson ? JSON.parse(fromCms?.bioJson) : {},
    // image: fromCms?.image,
    // owner: fromCms?.owner,
    slug: fromApi?.slug,
    title: fromApi?.first_name,
    // totalUsers: fromCms?.users?.length || 0,
    // users: fromCms?.users,
    firstName: fromApi?.first_name,
    cancerType: fromApi?.cancer_type,
    cancerStage: fromApi?.cancer_stage,
    howLongAgoType: fromApi?.how_long_ago_type,
    howLongAgoNumber: fromApi?.how_long_ago_number,
    insuranceProvider: fromApi?.insurance_provider,
    zipCode: fromApi?.zip_code,
    subscriptionInfo: {
      role: {
        label: fromApi?.subscription_info?.role?.label,
        shortCode: fromApi?.subscription_info?.role?.short_code,
      },
      isAdmin: fromApi?.subscription_info?.is_admin,
      user: {
        firstName: fromApi?.subscription_info?.user?.first_name,
        lastName: fromApi?.subscription_info?.user?.last_name,
        email: fromApi?.subscription_info?.user?.email,
      },
    },
  };
}
