/* eslint-disable @typescript-eslint/lines-between-class-members */
import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, runInAction } from 'mobx';
import xw from 'xwind';
import toast from 'react-hot-toast';
import challengesService from '@/services/challengesService';
import { Challenge, CreateChallengeData } from '@/types/Content/Challenges';
import { OrgStore } from '.';

export const coachesFirstPage = 0;

class ChallengesStore {
  challengesLoading = false;
  challengesLoaded = false;
  challengesError: any;
  challenges: Challenge[] | null = null;

  error: any;

  editChallenge: Partial<Challenge> | null = null;
  openEditChallengeModal(item: Partial<Challenge>) {
    this.editChallenge = item;
  }
  closeEditChallengeModal() {
    this.editChallenge = null;
  }

  openedChallengeBoard: Challenge | null = null;
  openChallengeBoardModal(item: Challenge) {
    this.openedChallengeBoard = item;
  }
  closeChallengeBoardModal() {
    this.openedChallengeBoard = null;
  }

  deleteChallengeId: string | null = null;
  openDeleteChallengeModal(id: string) {
    this.deleteChallengeId = id;
  }
  closeDeleteChallengeModal() {
    this.deleteChallengeId = null;
  }
  deletingChallengeId: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchChallenges(update = false) {
    try {
      if (this.challengesLoaded && !update && this.challenges?.length) return;

      runInAction(() => {
        this.challengesLoading = true;
      });
      const res = await challengesService.getChallenges();
      this.challenges = res?.challenges || null;
    } catch (error) {
      captureException(error);
      this.challengesError = error.message;
    } finally {
      runInAction(() => {
        this.challengesLoading = false;
        this.challengesLoaded = true;
      });
    }
  }

  async saveChallenge(data: CreateChallengeData, id?: string) {
    if (!OrgStore.isAdminOrOwnerUser) return;
    if (id) {
      await challengesService.editChallenge(data, OrgStore.org?.id, id);
    } else {
      await challengesService.createChallenge(data, OrgStore.org?.id);
    }
    await this.fetchChallenges(true);

    toast(id ? 'Saved!' : 'Created!', {
      duration: 3000,
      style: xw`px-4 text-active`,
    });
  }

  async deleteChallenge(id: string) {
    if (!OrgStore.isAdminOrOwnerUser) return;
    try {
      this.closeDeleteChallengeModal();
      this.deletingChallengeId = id;
      await challengesService.deleteChallenge(OrgStore.org?.id, id);
      await this.fetchChallenges(true);
      this.closeEditChallengeModal();
      toast('Deleted!', {
        duration: 3000,
        style: xw`px-4 text-active`,
      });
    } catch (error) {
      if (error?.message)
        toast(error?.message, {
          duration: 3000,
          style: xw`px-4 text-active`,
        });
    } finally {
      this.deletingChallengeId = null;
    }
  }
}

const store = new ChallengesStore();

export default store;
