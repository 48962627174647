import { makeAutoObservable, runInAction } from 'mobx';
import * as Sentry from '@sentry/nextjs';

import { ICompany, IEmployee, TResourcesRes } from '@/types/Company';
import companyService from '@/services/companyService';

class CompanyStore {
  company: ICompany | null = null;

  loadingCompany = false;

  employees: IEmployee[] | null = null;

  resources: TResourcesRes | null = null;

  loadingEmployees = false;

  loadingInvite = false;

  loadingRemoveUser = false;

  loadingResources = false;

  private _loadingCreateFolder = false;

  get loadingCreateFolder(): boolean {
    return this._loadingCreateFolder;
  }

  set loadingCreateFolder(value: boolean) {
    runInAction(() => {
      this._loadingCreateFolder = value;
    });
  }

  loadingRemove = false;

  errors: Record<string, string | null> = {
    createFolder: null,
  };

  protected service = companyService;

  constructor() {
    makeAutoObservable(this);
  }

  async getCompany(slug: string): Promise<ICompany | null> {
    try {
      this.loadingCompany = true;
      const company = await this.service.getCompany(slug);
      this.company = company;
      return company;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    } finally {
      this.loadingCompany = false;
    }
  }

  async getEmployees() {
    try {
      this.loadingEmployees = true;
      this.employees = await this.service.getEmployees(this.company.slug);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.loadingEmployees = false;
    }
  }

  async getResources(
    path: string[],
    companySlug?: string,
  ): Promise<TResourcesRes | null> {
    let newResources = null;
    runInAction(() => {
      this.loadingResources = true;
    });
    try {
      newResources = await this.service.getResources({
        slug: companySlug || this.company.slug,
        path,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    runInAction(() => {
      this.resources = newResources;
      this.loadingResources = false;
    });
    return newResources;
  }

  clearResources() {
    this.resources = null;
  }

  async uploadFiles(params: { fileList: File[]; path: string[] }) {
    this.resources = await this.service.uploadFiles({
      ...params,
      slug: this.company.slug,
    });
  }

  async createFolder(params: { name: string; path: string[] }) {
    this.resources = await this.service.createFolder({
      ...params,
      slug: this.company.slug,
    });
  }

  async removeResource(params: { slug: string; path: string[] }) {
    try {
      const { path } = params;
      this.loadingRemove = true;
      await this.service.removeResource({
        ...params,
        companySlug: this.company.slug,
      });
      this.getResources(path);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.loadingRemove = false;
    }
  }

  async inviteEmployees(invites: { name: string; email: string }[]) {
    try {
      this.loadingInvite = true;
      await this.service.inviteEmployees({
        data: invites,
        slug: this.company.slug,
      });
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.loadingInvite = false;
    }
  }

  async removeEmployee(invitationEmail: string) {
    try {
      this.loadingRemoveUser = true;
      await this.service.removeEmployee({
        data: { invitationEmail },
        slug: this.company.slug,
      });
      this.getEmployees();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      this.loadingRemoveUser = false;
    }
  }

  hydrate(company: ICompany) {
    if (company) {
      this.company = company;
    }
  }
}

export default new CompanyStore();
