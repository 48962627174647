import { makeAutoObservable } from 'mobx';

import * as Sentry from '@sentry/nextjs';
import { Product, ProductResponse } from '@/types/Content/Product/Product';
import { HTTPListResponse } from '@/types/HTTPListResponse';
import { Variant } from '@/types/Content/Product/Variant';
import { Option } from '@/types/Content/Product/Option';
import { ProductTypes } from '@/common/constants/ProductTypes';
import { Image } from '@/types/Content/Image';
import productService from '@/services/productService';
import { ProductsParams } from '@/types/Content/Product/ProductParams';

type ProductListingResponse = HTTPListResponse<ProductResponse>;

type AffiliateStore = {
  iconUrl: string;
  id: string;
  name: string;
};

type AffiliateListingResponse = HTTPListResponse<AffiliateStore>;

function mapProductResponse(product: ProductResponse | null): Product {
  const resourceType = { type: ProductTypes.DEFAULT };
  const image: Image = { url: product?.image?.url ?? '', alt: '' };
  let mappedProduct = {
    ...product,
  };

  // delete mappedProduct.type;
  // delete mappedProduct.image?.url;

  mappedProduct = {
    ...mappedProduct,
    image,
    resourceType,
  };

  return mappedProduct as Product;
}

function mapProductList(productList: ProductResponse[]): Product[] {
  return productList.map(mapProductResponse);
}

class ProductStore {
  protected service = productService;

  loading = false;

  loadingAffiliates = false;

  lastCacheDate: number = null;

  lastCacheDateAffiliates: number = null;

  page = 0;

  tags: string[] = [];

  product: Product = null;

  affiliates: Array<AffiliateStore> = [];

  productList: Array<Product> = [];

  count: number = null;

  totalCount: number = null;

  next: string = null;

  currentVariant: Variant = null;

  currentOption: Option = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentVariant(variant: Variant) {
    this.currentVariant = variant;
  }

  setCurrentOption(option: Option) {
    this.currentOption = option;
  }

  setProductList(productList: Array<Product>) {
    this.lastCacheDate = Date.now();
    this.productList = productList;
  }

  setAffiliatesList(affiliates: AffiliateStore[]) {
    this.lastCacheDateAffiliates = Date.now();
    this.affiliates = affiliates;
  }

  setRawProductList(
    productList: Array<Product>,
    productsParams: ProductsParams,
  ) {
    const mappedList = mapProductList(productList as ProductResponse[]);
    this.tags = [...productsParams.tags];
    this.page = productsParams.page;
    this.setProductList(mappedList);
    return mappedList;
  }

  clearProduct() {
    this.product = null;
  }

  async getProduct(slug: string): Promise<Product | null> {
    this.loading = true;
    try {
      const data = await this.service.getBySlug(slug);
      if (data) {
        this.product = mapProductResponse(data);
      }

      return this.product;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    } finally {
      this.loading = false;
    }
  }

  async getAffiliates(): Promise<Array<AffiliateStore>> {
    if (
      (this.affiliates.length &&
        this.lastCacheDateAffiliates &&
        Date.now() - this.lastCacheDateAffiliates <= 5 * 60 * 1000) ||
      this.loadingAffiliates
    ) {
      return this.affiliates;
    }
    try {
      this.loadingAffiliates = true;
      const {
        data,
      }: {
        data: AffiliateListingResponse;
      } = await this.service.getAffiliates();
      if (data.results.length) {
        this.setAffiliatesList(data.results);
      }
      return data.results;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    } finally {
      this.loadingAffiliates = false;
    }
  }

  async getAllProducts(
    page = 0,
    newTags: Array<string> = [],
    fillOnEmptyResponse = false,
  ): Promise<Array<Product>> {
    if (
      this.productList.length &&
      this.lastCacheDate &&
      this.page === page &&
      JSON.stringify(this.tags) === JSON.stringify(newTags) &&
      Date.now() - this.lastCacheDate <= 5 * 60 * 1000
    ) {
      return this.productList;
    }
    this.loading = true;
    this.page = page;
    this.tags = newTags;

    type Params = {
      page: number;
      tags?: string[];
      fillOnEmptyResponse: boolean;
    };

    try {
      const params: Params = {
        page,
        fillOnEmptyResponse,
      };

      // Temporal fix: only define tags property if there are any, otherwise api response time bloats up.
      if (newTags.length) {
        params.tags = newTags;
      }

      const {
        data,
      }: { data: ProductListingResponse } = await this.service.getAll(params);

      const { results, totalCount, count, next } = data;

      this.setProductList(results?.map(mapProductResponse) || []);
      this.totalCount = totalCount;
      this.count = count;
      this.next = next;
      return this.productList;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    } finally {
      this.loading = false;
    }
  }
}

const productStore = new ProductStore();

export default productStore;
