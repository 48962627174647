/* eslint-disable class-methods-use-this */
import { makeAutoObservable } from 'mobx';

import { api } from '../services';

type Answer = {
  value: string;
  field: number;
};

const INITIAL_SURVEY = {
  pages: [],
  prefilled_data: {},
};
const INITIAL_STAGE = 0;
const INITIAL_USER_ROLE = undefined;

class SurveyStore {
  survey = INITIAL_SURVEY;

  stage = INITIAL_STAGE;

  _userRole = INITIAL_USER_ROLE;

  constructor() {
    makeAutoObservable(this);
  }

  get countOfPages() {
    return this.survey?.pages?.length ?? 0;
  }

  get lastIndex() {
    return this.countOfPages > 0 ? this.countOfPages - 1 : 0;
  }

  get progress() {
    return this.countOfPages > 0 ? (this.stage / this.countOfPages) * 100 : 0;
  }

  get userRole() {
    return this._userRole;
  }

  identifyUserRole(values: string[] | string) {
    if (Array.isArray(values)) {
      this._userRole = values.sort().join('');
    } else {
      this._userRole = values;
    }
  }

  async fetchSurvey() {
    try {
      const response = await api.get('/questionnaire/');
      this.survey = response.data;
    } catch (error) {
      //
    }
  }

  sendAnswers({ answers, page }: { answers: Answer[]; page: number }) {
    return api.post('/questionnaire/answer/', {
      answers,
      page,
    });
  }

  sendInvitations(values) {
    return api.post('/village/invites/default_village/', values);
  }

  reset() {
    this.survey = INITIAL_SURVEY;
    this.stage = INITIAL_STAGE;
    this._userRole = INITIAL_USER_ROLE;
  }

  start() {
    this.reset();
  }

  completeSurvey() {
    return api.patch('/user/questionnaire/info/', {
      page_stopped: this.lastIndex,
      gift_finished: true,
      finished: true,
    });
  }

  async markQuestionnaireViewed() {
    try {
      await api.patch('/user/questionnaire/info/', {
        viewed: true,
      });
    } catch (error) {
      //
    }
  }

  navigateToNextPage() {
    let isFinish = false;
    if (this.stage < this.lastIndex) {
      this.stage += 1;
    } else {
      isFinish = true;
    }

    return { isFinish };
  }
}

export default new SurveyStore();
