import { ICmsUser, IUser } from '@/types/Content/User';
import * as Sentry from '@sentry/nextjs';
import { api } from '@/services/api';
import { FridgeDoorVillageType } from '@/types/Village/FridgeDoorVillage';
import { ICompany } from '@/types/Company';
import { cmsProxyApi } from './cmsProxyApi';

interface HTTPResponse {
  data: ICmsUser;
}

const userService = {
  async getCurrentUser(): Promise<ICmsUser> {
    try {
      const { data }: HTTPResponse = await cmsProxyApi.get<ICmsUser>(`/users`);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async patchUser(userData: Partial<IUser>): Promise<IUser> {
    try {
      const { data } = await api.patch<IUser>(`/user/`, userData);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async subscribeUser(groups: string[]): Promise<ICmsUser> {
    try {
      const { data }: HTTPResponse = await cmsProxyApi.post(
        `/users/subscribe`,
        { groups },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getUserFeed(page = 0): Promise<any> {
    try {
      const { data }: HTTPResponse = await cmsProxyApi.get(`/users/feed`, {
        params: { page },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async acceptInvite(): Promise<FridgeDoorVillageType[]> {
    const { data: villages } = await api.post<FridgeDoorVillageType[]>(
      '/village/invites/accept/',
    );
    return villages;
  },

  async acceptOrganizationInvite(): Promise<ICompany[]> {
    const { data: organizations } = await cmsProxyApi.post<ICompany[]>(
      '/organizations/invites/accept-all',
    );
    return organizations;
  },
  async updateUserImage({ file }: { file: File }): Promise<any> {
    try {
      const endpoint = `/users/image`;
      const formData = new FormData();
      formData.append('imageFile', file);

      const config = {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${file?.size};`,
          'Content-Disposition': `forma-data; name="imageFile"; filename=${file.name};`,
        },
      };

      const response = await cmsProxyApi.patch(endpoint, formData, config);

      return response?.data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default userService;
