import * as Sentry from '@sentry/nextjs';
import { cmsProxyApi } from './cmsProxyApi';

export type TutorialVideo = {
  id: string;
  title: string;
  videoUrl: string;
  imageUrl: string;
  updatedAt: string;
};
interface HTTPResponse {
  data: {
    totalCount: number;
    count: number;
    next: boolean;
    previous: boolean;
    results: TutorialVideo[];
  };
}

const tutorialsService = {
  async getVideos({ page = 0, perPage = 8 } = {}): Promise<
    HTTPResponse['data'] | null
  > {
    try {
      const params: any = { page, perPage, includeTotalCount: true };
      const { data }: HTTPResponse = await cmsProxyApi.get(
        '/tutorials/videos',
        {
          params,
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};
export default tutorialsService;
