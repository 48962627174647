export const GUEST_USER = 'guest';

export const BASE_USER_ROLES = {
  PATIENT: 'Patient',
  SURVIVOR: 'Survivor',
  SUPPORTER: 'Supporter',
  ALL: 'all',
};

export const USER_ROLE_PRIORITIES = {
  [BASE_USER_ROLES.PATIENT]: 1,
  [BASE_USER_ROLES.SUPPORTER]: 2,
  [BASE_USER_ROLES.SURVIVOR]: 3,
  [BASE_USER_ROLES.ALL]: 3,
};

export const USER_ROLE_IDS = {
  [BASE_USER_ROLES.PATIENT]: 1,
  [BASE_USER_ROLES.SURVIVOR]: 2,
  [BASE_USER_ROLES.SUPPORTER]: 4,
  [BASE_USER_ROLES.ALL]: 4,
};
