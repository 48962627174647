/* eslint-disable no-param-reassign */
import getPublicConfig from '@/utils/getPublicConfig';
import axios from 'axios';

const publicRuntimeConfig = getPublicConfig();

const API_URL = typeof window !== 'undefined' && window.location.host && publicRuntimeConfig.APP_ENV === 'development'
  ? 'http://localhost:8989'
  : publicRuntimeConfig.API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async (config) => {
    let accessToken;
    if (typeof window !== 'undefined' && window.localStorage) {
      accessToken = localStorage?.getItem('access_token');
    }

    if (accessToken) {
      config.headers = {
        Authorization: `Token ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export { api };
