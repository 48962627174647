/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';

import { loggedActionsGuard } from '@/utils/loggedActionsGuard';
import { cmsProxyApi } from '@/services/cmsProxyApi';
import wishlistService from '@/services/wishlistService';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import { api } from '../services';
import AuthStore from './AuthStore';

type ProductType = {
  [key: string]: any;
};

interface WishlistType {
  id: number;
  is_public: boolean;
  products: Array<ProductType>;
  [key: string]: any;
}

const initialValue: WishlistType = {
  id: null,
  is_public: null,
  products: [],
};

class WishlistStore {
  isOpenShippingAddressModal = false;

  isMakingPublic = false;

  isWishlistLoading = false;

  wishlist = initialValue;

  wishlistMap = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  openShippingAddressModal() {
    this.isOpenShippingAddressModal = true;
  }

  closeShippingAddressModal() {
    this.isOpenShippingAddressModal = false;
  }

  getWishlistByVillageId(villageId: string) {
    return this.wishlistMap.get(villageId);
  }

  getProductsByVillageId(villageId: string) {
    const wishlist = this.wishlistMap.get(villageId);
    if (Array.isArray(wishlist?.products)) {
      return wishlist.products;
    }

    return [];
  }

  async fetchWishlist(villageSlug?: string) {
    try {
      this.isWishlistLoading = true;

      const data = await wishlistService.getProducts({ villageSlug });
      if (villageSlug) {
        this.wishlistMap.set(villageSlug, data);
      }
    } catch (error) {
      //
    }

    this.isWishlistLoading = false;
  }

  async makePublic(villageId?: string) {
    try {
      this.isMakingPublic = true;

      const apiUrl = villageId ? `/wishlist/v/${villageId}/` : '/wishlist/';
      const { data } = await cmsProxyApi.patch(apiUrl, {
        is_public: true,
      });

      if (data) {
        this.wishlistMap.set(villageId, data);
      }
    } catch (error) {
      //
    }

    this.isMakingPublic = false;
  }

  private async _addProduct(
    productId: number,
    variantId: number,
    productType: string,
    villageId?: string,
  ): Promise<void> {
    const apiUrl = villageId
      ? `/wishlist/v/${villageId}/product/`
      : '/wishlist/products/';

    await api.post(apiUrl, {
      product_id: productId,
      variant_id: variantId,
      sanity_types: productType,
    });

    await this.fetchWishlist(villageId);
  }

  async addProduct(
    productId: number | string,
    variantId: number,
    productType: string,
    villageId?: string,
  ): Promise<void> {
    const handler = (loggedActionsGuard(
      AuthStore?.isLoggedIn,
      FridgeDoorStore.isMustCreateVillage,
      this._addProduct.bind(this) as (...args: any) => any,
    ) as unknown) as (
      producId: number | string,
      variantId: number,
      productType: string,
      villageId?: string,
    ) => Promise<void>;

    await handler(productId, variantId, productType, villageId);
  }

  async changeCount(
    productId: number,
    variantId: number,
    value: number,
    villageId?: string,
  ) {
    if (value === 0) return;

    try {
      const apiUrl = villageId
        ? `/wishlist/v/${villageId}/product/${productId}/${variantId}/`
        : `/wishlist/products/${productId}/${variantId}/`;

      const res = await api.patch(apiUrl, {
        count: value,
      });

      if (this.wishlistMap.has(villageId)) {
        const currentWishlist = this.wishlistMap.get(villageId);
        const currentProduct = currentWishlist?.products?.find(
          (product) => product?.variant?.id === variantId,
        );
        if (currentProduct) {
          currentProduct.count = res.data?.count;
        }
      }
    } catch (error) {
      //
    }
  }

  async removeProduct(elementId: number, villageId?: string) {
    const apiUrl = `/wishlist/v/${villageId}/product/${elementId}`;

    await api.delete(apiUrl);
    await this.fetchWishlist(villageId);
  }

  checkAlreadyAdded(variantId: string, villageId?: string) {
    if (this.wishlistMap.has(villageId)) {
      const wishlist = this.wishlistMap.get(villageId);
      const index = wishlist?.products?.findIndex(
        (product) => product?.variant?.id === variantId,
      );

      return index !== -1;
    }
    return false;
  }

  reset() {
    this.wishlist = initialValue;
    this.wishlistMap = new Map();
  }
}

export default new WishlistStore();
