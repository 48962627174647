import * as Sentry from '@sentry/nextjs';

import { HTTPListResponse } from '@/types/HTTPListResponse';
import { Notification, UnreadCountResponse } from '@/types/Notifications';
import { api } from './api';

const notificationsService = {
  async getNotifications({
    page,
  }: {
    page: number;
  }): Promise<{
    results: Notification[];
    count: number;
  }> {
    try {
      const {
        data: { results, count },
      } = await api.get<HTTPListResponse<Notification>>(`/notifications`, {
        params: { page },
      });
      return {
        results,
        count,
      };
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async readAllNotifications(): Promise<any> {
    try {
      const { data } = await api.post(`/notifications/read-all/`);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async readNotification(notificId: number): Promise<any> {
    try {
      const { data } = await api.patch(`/notifications/${notificId}`);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getUnreadNotificationsCount(): Promise<UnreadCountResponse> {
    try {
      const { data } = await api.get<UnreadCountResponse>(
        `/notifications/unread/`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default notificationsService;
