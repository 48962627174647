import * as Sentry from '@sentry/nextjs';
import { cmsProxyApi } from './cmsProxyApi';

export type GuidanceVideo = {
  id: string;
  title: string;
  videoUrl?: string;
  youtubeVideoId?: string;
  imageUrl: string;
  updatedAt?: string;
  pinToTop?: boolean;
};
interface HTTPResponse {
  data: {
    totalCount: number;
    count: number;
    next: boolean;
    previous: boolean;
    results: GuidanceVideo[];
  };
}

const guidanceService = {
  async getVideos({ page = 0, perPage = 8 } = {}): Promise<
    HTTPResponse['data'] | null
  > {
    try {
      const params: any = { page, perPage, includeTotalCount: true };
      const { data }: HTTPResponse = await cmsProxyApi.get(
        '/guidanceHub/videos',
        {
          params,
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};
export default guidanceService;
