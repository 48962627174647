import * as Sentry from '@sentry/nextjs';
import { Challenge, CreateChallengeData } from '@/types/Content/Challenges';
import { api } from './api';

export type HTTPResponseChallenges = {
  data: { challenges: Challenge[] };
};

const challengesService = {
  async getChallenges(): Promise<HTTPResponseChallenges['data'] | null> {
    try {
      const { data }: HTTPResponseChallenges = await api.get(`challenges/`);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async createChallenge(data: CreateChallengeData, orgId?: string) {
    try {
      await api.post(`/companies/${orgId}/challenges/`, data);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async editChallenge(
    data: CreateChallengeData,
    orgId?: string,
    challengeId?: string,
  ) {
    try {
      await api.patch(`/companies/${orgId}/challenges/${challengeId}`, data);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async deleteChallenge(orgId?: string, challengeId?: string) {
    try {
      await api.delete(`/companies/${orgId}/challenges/${challengeId}`);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};

export default challengesService;
