import { NextRouter, SingletonRouter } from 'next/router';

export const stopPropagation = (e) => e?.stopPropagation();

export const getArrayFromNum = (num: number) => Array.from(Array(num).keys());

export const getRandomInt = (min: number, max: number) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _min = Math.ceil(min);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _max = Math.floor(max);
  return Math.floor(Math.random() * (_max - _min + 1)) + _min;
};

export const getLandingCoachSlugQuery = (
  router: SingletonRouter | NextRouter,
) => {
  let query: { coach: string; schedule?: number } | undefined;
  if (router.pathname === '/coach/[slug]' && router.query?.slug) {
    query = { coach: router.query.slug as string };
    if (router.query?.schedule) {
      query.schedule = 1;
    }
  }
  return query;
};
