export const HOME_PATH = '/';

export const FEED_PATH = '/u';

export const BLOG_URL_PATH = '/resource';

export const TOOLS_PATH = '/tools';

export const COLLECTIONS_PATH = '/collections';

export const WISHLIST_PATH = '/wishlist';

export const CHECKLIST_PATH = '/checklist';

export const SURVEY_PATH = '/survey';

export const VILLAGE_PATH = '/vlg';

export const ORG_PATH = '/org';

export const PRODUCTS_PATH = '/product';

export const RESOURCES_PATH = '/resource';

export const DISCOVER_PATH = '/discover';

export const DISCUSSIONS_PATH = '/discussions';

export const SHOP_PATH = '/shop';

export const PROFILE_BUILDER = '/profile-builder';

export const SETTINGS_PATH = '/settings';

export const COMPANY_PATH = '/company';

export const SIGNUP_PB_REDIRECT_BLACKLIST = [
  SHOP_PATH,
  WISHLIST_PATH,
  PROFILE_BUILDER,
  PRODUCTS_PATH,
];
