/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';
import AuthStore from './AuthStore';

export type AuthModalType =
  | 'letsStart'
  | 'tellUsMore'
  | 'signup'
  | 'emailSent'
  | 'login'
  | 'forgotPassword'
  | 'forgotPasswordNotice'
  | 'resetPassword';

const AuthModalTypes = [
  'letsStart',
  'tellUsMore',
  'signup',
  'login',
  'forgotPassword',
  'forgotPasswordNotice',
  'resetPassword',
];

class AuthModalStore {
  private _isInvalidResetToken = false;

  isOpen = false;

  type: AuthModalType = 'login';

  constructor() {
    makeAutoObservable(this);
  }

  get isInvalidResetToken() {
    return this._isInvalidResetToken;
  }

  set isInvalidResetToken(value: boolean) {
    this._isInvalidResetToken = value;
  }

  isSignupClicked = false;

  // eslint-disable-next-line class-methods-use-this
  getToType(type: AuthModalType): AuthModalType {
    const isSignPages = ['login', 'signup'].includes(type);
    if (isSignPages) {
      this.isSignupClicked = type === 'signup';
    }

    if (!AuthStore.providerInfoExists && isSignPages) {
      return 'letsStart';
    }
    if (
      AuthStore.providerInfoExists &&
      AuthStore.showMore &&
      !AuthStore.companyName &&
      type === 'signup'
    ) {
      return 'tellUsMore';
    }
    return type;
  }

  showModal(type: AuthModalType) {
    if (!AuthStore.isLoggedIn && AuthModalTypes.includes(type)) {
      this.isOpen = true;
      this.type = this.getToType(type);
    }
  }

  closeModal() {
    this.isOpen = false;
  }

  switchForm(toType: AuthModalType) {
    if (this.type !== toType) {
      this.type = this.getToType(toType);
    }
  }
}

export default new AuthModalStore();
