import * as Sentry from '@sentry/nextjs';

import { ICompany, IEmployee, TResourcesRes } from '@/types/Company';
import { HTTPListResponse } from '@/types/HTTPListResponse';
import { cmsProxyApi } from './cmsProxyApi';

const companyService = {
  async getCompany(slug: string): Promise<ICompany> {
    try {
      const { data } = await cmsProxyApi.get<ICompany>(
        `/organizations/${slug}`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getEmployees(slug: string): Promise<IEmployee[]> {
    try {
      const { data } = await cmsProxyApi.get<HTTPListResponse<IEmployee>>(
        `/organizations/${slug}/employee`,
      );

      return data.results;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async inviteEmployees({
    data,
    slug,
  }: {
    data: { name: string; email: string }[];
    slug: string;
  }): Promise<void> {
    try {
      await cmsProxyApi.post(`/organizations/${slug}/invite`, data);
    } catch (error) {
      Sentry.captureException(error);
    }
  },

  async removeEmployee({
    data,
    slug,
  }: {
    data: { invitationEmail: string };
    slug: string;
  }): Promise<void> {
    try {
      await cmsProxyApi.post(`/organizations/${slug}/users/remove`, data);
    } catch (error) {
      Sentry.captureException(error);
    }
  },

  async getResources({
    slug,
    path,
  }: {
    slug: string;
    path: string[];
  }): Promise<TResourcesRes> {
    const { data } = await cmsProxyApi.get<TResourcesRes>(
      `organizations/${slug}/resources/${path.join('/')}`,
    );

    return data;
  },

  async createFolder({
    slug,
    path,
    name,
  }: {
    slug: string;
    path: string[];
    name: string;
  }): Promise<TResourcesRes> {
    const { data } = await cmsProxyApi.post<TResourcesRes>(
      `organizations/${slug}/resources/${path.join('/')}`,
      {
        title: name,
        type: 'organizationResourcesFolder',
      },
    );
    return data;
  },

  async removeResource({
    companySlug,
    path,
    slug,
  }: {
    companySlug: string;
    path: string[];
    slug: string;
  }): Promise<void> {
    await cmsProxyApi.delete<TResourcesRes>(
      `organizations/${companySlug}/resources/${[...path, slug].join('/')}`,
    );
  },

  async uploadFiles({
    fileList,
    slug,
    path,
  }: {
    fileList: File[];
    slug: string;
    path: string[];
  }): Promise<TResourcesRes> {
    const formData = new FormData();
    fileList.forEach((item, index) => {
      formData.append(`file${index}`, item);
    });
    const { data } = await cmsProxyApi.post<TResourcesRes>(
      `/organizations/${slug}/resources/${path.join('/')}/upload`,
      formData,
    );
    return data;
  },
};

export default companyService;
