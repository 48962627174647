import { AxiosRequestConfig } from 'axios';
import { captureException } from '@sentry/nextjs';
import { ProductResponse } from '@/types/Content/Product/Product';
import { cmsProxyApi } from './cmsProxyApi';

interface GetAllParams {
  page: number;
  tags?: string[];
}

async function getAll(params: GetAllParams): Promise<any> {
  try {
    const config: AxiosRequestConfig = {
      params: {
        ...params,
      },
    };

    return await cmsProxyApi.get(`/products`, config);
  } catch (error) {
    captureException(error);

    return null;
  }
}

async function getBySlug(slug: string): Promise<ProductResponse | null> {
  try {
    const { data: product } = await cmsProxyApi.get<ProductResponse>(
      `/products/${slug}`,
    );

    return product;
  } catch (error) {
    if (error?.response?.status !== 404) {
      captureException(error);
    }
    return null;
  }
}

async function getAffiliates(): Promise<any> {
  try {
    return await cmsProxyApi.get(`/products/affiliates`);
  } catch (error) {
    captureException(error);

    return null;
  }
}

export default {
  getAll,
  getBySlug,
  getAffiliates,
};
