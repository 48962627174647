/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function getPublicConfig() {
  return {
    API_URL: process.env.NEXT_PUBLIC_API_URL,
    APP_ENV: process.env.NEXT_PUBLIC_APP_ENV || 'production',
    AWS_S3_URL: process.env.NEXT_PUBLIC_AWS_S3_URL,
    CMS_PROXY_URL: process.env.NEXT_PUBLIC_CMS_PROXY_URL,
    SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
    FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
    FRAMEWORK_SIGNIN_URL: process.env.NEXT_PUBLIC_FRAMEWORK_SIGNIN_URL,
    GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    GTM_KEY: process.env.NEXT_PUBLIC_GTM_KEY,
    LOCATION_ORIGIN: process.env.NEXT_PUBLIC_LOCATION_ORIGIN,
    SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    STOREFRONT_API_GRAPHQL: process.env.NEXT_PUBLIC_STOREFRONT_API_GRAPHQL,
    VIEW_MEDICA_CLIENT_ID: process.env.NEXT_PUBLIC_VIEW_MEDICA_CLIENT_ID,
    VIMEO_APP_ID: process.env.NEXT_PUBLIC_VIMEO_APP_ID,
    VIMEO_HASH_ID: process.env.NEXT_PUBLIC_VIMEO_HASH_ID,
    APPLE_CLIENT_ID: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
    GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    X_SHOPIFY_STOREFRONT_ACCESS_TOKEN:
      process.env.NEXT_PUBLIC_X_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    SCRAPER_GOOGLE_AGENT:
      process.env.NEXT_SCRAPER_GOOGLE_AGENT ||
      'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
    SCRAPER_BROWSER_AGENT:
      process.env.NEXT_SCRAPER_BROWSER_AGENT ||
      'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Mobile Safari/537.36',
  };
}
