import coachingService from '@/services/coachingService';
import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, runInAction } from 'mobx';
import { Coach, CoachCategory } from '@/types/Content/Coaching';
import { NavigatorsHeader } from '@/types/Content/NavHeader';
import axios from 'axios';

export const coachesFirstPage = 0;
export const allCoachingCategoryKey = 'all';

class CoachingStore {
  loading = false;

  loadingHeader = false;

  coaches: Coach[] = [];

  headerInfo: NavigatorsHeader = null;

  nextPageExists = false;

  page = coachesFirstPage;

  lastPerPage = 6;

  constructor() {
    makeAutoObservable(this);
  }

  sendCalendlyEventAdded = false;

  setSendCalendlyEventAdded(val: boolean) {
    this.sendCalendlyEventAdded = val;
  }

  async fetchNavHeaders() {
    try {
      if (this.loadingHeader) return;

      runInAction(() => {
        this.loadingHeader = true;
      });

      const data = await coachingService.getNavHeaders();

      this.headerInfo = data || null;
    } catch (error) {
      captureException(error);
    } finally {
      runInAction(() => {
        this.loadingHeader = false;
      });
    }
  }

  coachingCancelTokenSource: any | null = null;

  async fetchCoaching({
    page = coachesFirstPage,
    perPage,
    homePageOnly,
    refresh = false,
    category,
    categoryRef,
  }: {
    page?: number;
    perPage?: number;
    homePageOnly?: boolean;
    refresh?: boolean;
    category?: string;
    categoryRef?: React.MutableRefObject<string>;
  } = {}) {
    let cancelled = false;
    try {
      // we don't need to load page 0 if page 1 is already loaded (we don't have refresh functionality)
      if (!refresh && this.coaches?.length && page <= this.page) return;

      this.lastPerPage = perPage;

      this.page = this.coaches?.length ? page : coachesFirstPage;

      runInAction(() => {
        this.loading = true;
      });

      await this.coachingCancelTokenSource?.cancel('cancelled');
      this.coachingCancelTokenSource = axios.CancelToken.source();

      const coachesRes = await coachingService.getCoaching({
        page: this.page,
        perPage,
        homePageOnly,
        category,
        cancelToken: this.coachingCancelTokenSource?.token,
      });

      if (categoryRef && categoryRef.current !== category) return;

      this.nextPageExists = coachesRes?.next || false;
      if (this.page > coachesFirstPage) {
        this.coaches = [...this.coaches, ...(coachesRes?.result || [])];
      } else {
        this.coaches = coachesRes?.result || [];
      }
    } catch (error) {
      if (error?.message === 'cancelled') {
        cancelled = true;
      } else {
        captureException(error);
      }
    } finally {
      if (!cancelled && (!categoryRef || categoryRef?.current === category)) {
        runInAction(() => {
          this.loading = false;
        });
      }
    }
  }

  setLoadingCoaching(val: boolean) {
    this.loading = val;
  }

  categories: CoachCategory[] = [];

  async fetchCoachingCategories() {
    try {
      const res = await coachingService.getCoachingCategories();
      this.categories = res;
    } catch (error) {
      captureException(error);
    }
  }

  async calendlyEventAdded(inviteeUri: string) {
    try {
      runInAction(() => {
        this.loading = true;
      });
      await coachingService.eventAdded(inviteeUri);
    } catch (error) {
      captureException(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  async loadNextPage({
    category,
    categoryRef,
  }: {
    category: string | null;
    categoryRef?: React.MutableRefObject<string>;
  }) {
    if (!this.nextPageExists) return;

    await this.fetchCoaching({
      refresh: true,
      perPage: 50,
      category,
      categoryRef,
    });
  }
}

const store = new CoachingStore();

export default store;
