import { makeAutoObservable } from 'mobx';

class HomePageStore {
  private _isOnboardingVisible = false;

  private _isOnboardingCompleted = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isOnboardingVisible() {
    return this._isOnboardingVisible && !this._isOnboardingCompleted;
  }

  showOnboarding() {
    this._isOnboardingVisible = true;
  }

  hideOnboarding() {
    this._isOnboardingVisible = false;
  }

  completeOnboarding() {
    this._isOnboardingCompleted = true;
  }

  reset() {
    this._isOnboardingVisible = false;
    this._isOnboardingCompleted = false;
  }
}

export default new HomePageStore();
