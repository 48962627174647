/* eslint-disable import/no-cycle */
import { makeAutoObservable } from 'mobx';
import { PBUserRole } from '@/domains/ProfileBuilder/constants/PBUserRole';
import { AuthStore } from '@/stores/index';
import * as Sentry from '@sentry/nextjs';
import ProfileBuilderService from '@/services/profileBuilderService';
import {
  TCancerType,
  TPatientInfo,
  TLifeEventType,
  ILifeEventSubscriptionGroups,
} from '@/types/ProfileBuilder/profileBuilder';
import { PBStepNames } from '@/domains/ProfileBuilder/constants/PBStepName';
import { Group } from '@/types/Content/Group';
import subscriptionGroupService from '@/services/subscriptionGroupService';

class ProfileBuilderStore {
  private _isLoading = false;

  private _isLoadingGroups = false;

  private _canSelectRole = true;

  private _lifeEvent: TLifeEventType;

  cancerTypeList: TCancerType[] = [];

  lifeEventTypeList: TLifeEventType[] = [];

  lifeEventSubscriptionGroups: ILifeEventSubscriptionGroups[];

  _currentStep: string;

  _selectedUserRole: PBUserRole;

  _patientInfo: TPatientInfo = {
    name: '',
    life_event_type: '',
    role: 's',
  };

  loadingCancerTypes = false;

  loadingLifeEventTypes = false;

  private _subscriptionGroups: Group[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  get isLoadingGroups(): boolean {
    return this._isLoadingGroups;
  }

  get patientInfo() {
    return this._patientInfo;
  }

  get canSelectRole(): boolean {
    return this._canSelectRole;
  }

  setCanSelectRole(value: boolean) {
    this._canSelectRole = value;
  }

  async setLifeEvent(lifeEventId: string) {
    const lifeEvents = await this.getLifeEventTypes();
    this._lifeEvent = lifeEvents.find((v) => v.id === lifeEventId);
  }

  get lifeEvent() {
    return this._lifeEvent;
  }

  async getLifeEventTypes(): Promise<TLifeEventType[]> {
    if (this.lifeEventTypeList.length || this.loadingLifeEventTypes) {
      return this.lifeEventTypeList;
    }
    this.loadingLifeEventTypes = true;
    try {
      const newLifeEventTypeList = await ProfileBuilderService.getLifeEventType();
      this.lifeEventTypeList = newLifeEventTypeList;
      return newLifeEventTypeList;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    } finally {
      this.loadingLifeEventTypes = false;
    }
  }

  get subscriptionGroups(): Group[] {
    return this._subscriptionGroups;
  }

  async getSubscriptionGroups(selectedTags: string[]): Promise<Group[]> {
    try {
      this._isLoadingGroups = true;
      const { results } = await subscriptionGroupService.getSubscribable(
        selectedTags,
      );
      this._subscriptionGroups = results;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    } finally {
      this._isLoadingGroups = false;
    }
    return this._subscriptionGroups;
  }

  async getLifeEventSubscriptionGroups(
    lifeEventId: string,
  ): Promise<ILifeEventSubscriptionGroups[]> {
    try {
      const data = await ProfileBuilderService.getLifeEventSubscriptionGroups(
        lifeEventId,
      );
      this.lifeEventSubscriptionGroups = data;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
    return this.lifeEventSubscriptionGroups;
  }

  updatePatientInfo(patientInfo: TPatientInfo) {
    this._patientInfo = patientInfo;
  }

  changeCurrentStep(currentStep: string) {
    this._currentStep = currentStep;
    const isForCurrent = currentStep === PBStepNames.MORE_ABOUT_YOU;
    this.updatePatientInfo({
      ...this._patientInfo,
      name: isForCurrent ? AuthStore.cmsUser.firstName : '',
      role: isForCurrent ? 'p' : 's',
    });
  }
}

export default new ProfileBuilderStore();
