/* eslint-disable no-param-reassign */
import { captureException } from '@sentry/nextjs';
import { makeAutoObservable, runInAction } from 'mobx';
import type { VillagePage } from '@/types/Village/VillagePage';
import villageService from '@/services/villageService';
import { Parser } from '@/components/TextEditor/Parser.class';
import { ImageMapType } from '@/components/TextEditor/TextEditor.types';
import { imageUploadService } from '@/services/imageUploadService';
import { Reference } from '@sanity/types/dist/dts';
import wishlistService from '@/services/wishlistService';
import { WishlistProductItem } from '@/types/Village/WishlistPage';
import {
  FeedActivity,
  FeedActivityResponse,
} from '@/types/Village/FeedActivity';
import { ICmsUser } from '@/types/Content/User';
import { BlogBody } from '@/types/Content/BlogBody';
import { RawDraftContentState } from 'draft-js';

type PostToFeedType = {
  slug: string;
  author: ICmsUser;
  body: Array<Parser>;
  imageMap: ImageMapType;
};

class VillagePageStore {
  loading = false;

  loadingAll = false;

  loadingWishlist = false;

  loadingSaveProduct = false;

  wishlistContent: Array<WishlistProductItem> = [];

  village: VillagePage = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async fetchVillage(villageSlug: string) {
    runInAction(() => {
      this.setLoading(true);
    });

    const village = await villageService.getBySlug({ slug: villageSlug });

    runInAction(() => {
      this.setLoading(false);
      if (village) {
        this.setVillage(village);
      }
    });
  }

  setVillage(village: VillagePage) {
    this.village = village;
  }

  async addWishlistProduct(payload) {
    this.loadingSaveProduct = true;
    try {
      await wishlistService.createProduct(this.village.slug, {
        description: payload.note,
        image_url: payload.previewImage,
        price: payload.price,
        title: payload.title,
        url: payload.url,
        quantity: payload.quantity,
        needed_date: payload.date,
        sanity_types: 'externalProduct',
      });

      this.fetchWishlist();
    } catch (e) {
      captureException(e);
    }
    this.loadingSaveProduct = false;
  }

  async updateWishlistProduct(djangoId, payload) {
    this.loadingSaveProduct = true;
    try {
      await wishlistService.updateProduct(this.village.slug, djangoId, {
        description: payload.note,
        image_url: payload.previewImage,
        price: payload.price,
        title: payload.title,
        url: payload.url,
        quantity: payload.quantity,
        needed_date: payload.date,
        sanity_types: 'externalProduct',
      });

      this.fetchWishlist();
    } catch (e) {
      captureException(e);
    }
    this.loadingSaveProduct = false;
  }

  async fetchWishlist(initialLoading?: boolean) {
    if (initialLoading) {
      this.loadingWishlist = true;
    }

    const wishlistResponse = await wishlistService.getProducts({
      villageSlug: this.village?.slug,
    });

    runInAction(() => {
      this.wishlistContent = wishlistResponse?.products || [];

      if (initialLoading) {
        this.loadingWishlist = false;
      }
    });
  }

  async updateProductWishlistQuantity({
    quantity,
    operation,
    wishlistProductId,
  }: {
    quantity: number;
    wishlistProductId: number;
    operation: 'reserve' | 'undo-reserve' | 'purchase';
  }) {
    await wishlistService.updateQuantity({
      quantity,
      operation,
      wishlistProductId,
      villageSlug: this.village?.slug,
    });

    await this.fetchWishlist();
  }

  async removeWishlistItem({ productId }: { productId: number }) {
    const isDeletedSuccessfully = await wishlistService.removeProduct({
      productId,
      villageSlug: this.village?.slug,
    });

    if (!isDeletedSuccessfully) return;

    this.fetchWishlist();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }
}

export default new VillagePageStore();
