import * as Sentry from '@sentry/nextjs';

import { FilterValues } from '@/domains/Groups/constants/FilterValues';
import { Group } from '@/types/Content/Group';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import { cmsProxyApi } from './cmsProxyApi';

type GroupListParams = {
  page?: number;
  trending?: boolean;
  filterType?: string;
};

type JoinOrLeaveGroupParams = {
  groupId: string;
  userId: string;
};

const discoverService = {
  async getGroupList({
    page = 0,
    filterType = '',
    trending = true,
  }: GroupListParams): Promise<Group[] | null> {
    const { villageUserTags } = FridgeDoorStore;
    try {
      const { data } = await cmsProxyApi.get('/subscription-groups', {
        params: {
          page,
          trending,
          contentTrending: true,
          includeTotalCount: true,
          contentTypeList: filterType,
          tags: villageUserTags,
        },
      });
      return data.results;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getGroup(
    slug: string,
    contentRelatedTags: Array<string> = [],
    filter: Array<FilterValues>,
    trending = true,
  ): Promise<Group> {
    const typeFilter = discoverService.getFilter(filter);

    try {
      const { data } = await cmsProxyApi.get(`/subscription-groups/${slug}`, {
        params: {
          contentRelatedTags,
          typeFilter,
          trending,
        },
      });

      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  getFilter(filters: Array<string>): string {
    return (
      filters?.filter((filter) => filter !== FilterValues.ALL).join(',') || null
    );
  },

  async joinGroup({ userId, groupId }: JoinOrLeaveGroupParams): Promise<any> {
    try {
      return await cmsProxyApi.post(
        `/subscription-groups/${groupId}/subscribe/${userId}`,
      );
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async leaveGroup({ userId, groupId }: JoinOrLeaveGroupParams): Promise<any> {
    try {
      return await cmsProxyApi.delete(
        `/subscription-groups/${groupId}/subscribe/${userId}`,
      );
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
};

export default discoverService;
