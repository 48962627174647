import { DiagnosisType } from '@/domains/ProfileBuilder/pages/KeyEvents/KeyEvents.types';
import * as Sentry from '@sentry/nextjs';
import {
  TPatientInfo,
  TLifeEventType,
  TLifeEventTypeResponse,
  ILifeEventSubscriptionGroups,
} from '@/types/ProfileBuilder/profileBuilder';
import { FridgeDoorVillageType } from '@/types/Village/FridgeDoorVillage';
import { cmsProxyApi } from './cmsProxyApi';
import { api } from './api';

const ProfileBuilderService = {
  async getLifeEventSubscriptionGroups(
    LifeEventId: string,
  ): Promise<ILifeEventSubscriptionGroups[]> {
    try {
      const { data } = await cmsProxyApi.get<any>(
        `/life-event/${LifeEventId}/subscriptionGroups`,
      );

      return data.results;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  },
  async getLifeEventType(): Promise<TLifeEventType[]> {
    try {
      const { data } = await cmsProxyApi.get<TLifeEventTypeResponse>(
        '/life-event/list',
      );
      return data.results;
    } catch (error) {
      Sentry.captureException(error);
      return [];
    }
  },
  async createVillage(
    patientInfo: TPatientInfo,
  ): Promise<FridgeDoorVillageType> {
    try {
      const { data } = await api.post('/village/', patientInfo);

      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async addDiagnosis(diagnosis: DiagnosisType): Promise<DiagnosisType> {
    try {
      const { data } = await api.post(
        '/ov-profile/diagnosis-details',
        diagnosis,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async saveWorkEmail(workEmail: string): Promise<void> {
    try {
      await cmsProxyApi.post('/users/set-work-email', { workEmail });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
};

export default ProfileBuilderService;
